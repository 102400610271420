export enum ApiEndpoints {
  AuthSendConfirmation = 'send-confirmation',
  AuthConfirmEmail = 'confirm-email',
  AuthGoogle = 'google',
  AuthGoogleCallback = '/google/callback',
  AuthApple = 'apple',
  AuthAppleCallback = '/apple/callback',
  AuthLogin = 'login',
  AuthRefresh = 'refresh',
}
