import { IUser } from './user.interface';

export interface IUserSettings {
  id?: string;
  theme?: string;
  avatar?: string;
  bgColor?: string;
  user?: IUser;
  language?: string;
}

export type ICreateUserSettings = Pick<
  IUserSettings,
  'theme' | 'avatar' | 'bgColor' | 'user' | 'language'
>;
export type IUpdateUserSettings = Partial<Omit<IUserSettings, 'id'>>;

/**
 * this was added so that we can consistently know which User properties
 * will be exposed in API payloads
 */
export type IPublicUserSettings = Omit<
  IUserSettings,
  'userId' | 'theme' | 'avatar'
>;
