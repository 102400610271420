import { Injectable } from '@angular/core';
import { ClientEventBus, EventModel, Logger } from '@lc/client/util';
import { BehaviorSubject, filter, Observable } from 'rxjs';
import { AdminEventBusEnum } from './admin.enums';

@Injectable({
  providedIn: 'root',
})
export class AdminEventBusService
  extends Logger
  implements ClientEventBus<AdminEventBusEnum>
{
  private eventBus: BehaviorSubject<EventModel<AdminEventBusEnum>> =
    new BehaviorSubject<EventModel<AdminEventBusEnum>>({
      eventType: AdminEventBusEnum.Inited,
      data: undefined,
    });

  constructor() {
    super(AdminEventBusService.name);
  }
  emit(eventType: AdminEventBusEnum, eventOwner: string, data?: unknown): void {
    this.log(
      `event: "${eventType}" emitted by ${eventOwner}, data: ${JSON.stringify(
        data
      )}`
    );
    this.eventBus.next({ eventType, data });
  }

  on(eventType: AdminEventBusEnum): Observable<EventModel<AdminEventBusEnum>> {
    // console.log('on:', eventType);
    return this.eventBus.asObservable().pipe(
      filter((event) => {
        // this.log(`on, data: ${JSON.stringify(event)}`);
        return event.eventType === eventType;
      })
      // shareReplay(20)
    );
  }
}
