import { ITodo } from './todo.interface';
import { IPublicAuthSettings } from './auth-settings.interface';
import { IUserSettings } from './user-settings.interface';
import { Gender, Goal, UserRoles } from './auth.models';
import { SubscriptionType } from '../enums';
import { IPublicSubscription } from './test.interface';

export interface IUserShort {
  /** Randomly generated primary key (UUID) by the database */
  id: string;

  /**
   * We'll just use an email as a user identifier
   * instead of worrying about a username, or a
   * formal first/last name.
   */
  email: string;

  fullName?: string;

  gender?: Gender;

  birthday?: string;

  goal?: Goal;

  withPartner?: boolean;

  settings: IUserSettings;
}

export interface IUser extends IUserShort {
  /** Randomly generated primary key (UUID) by the database */
  id: string;

  /**
   * We'll just use an email as a user identifier
   * instead of worrying about a username, or a
   * formal first/last name.
   */
  email: string;

  /**
   * This is **NOT** the user's actual password! Instead,
   * this property will contain a hash of the password
   * specified when the user signed up. An API should
   * never be storing the actual password, encrypted or
   * not.
   */
  password: string;

  subscriptionType?: SubscriptionType;

  /**
   * A single user will be associated with zero, one, or more
   * to-do items, which means this field should never be
   * `undefined`. The object will always contain an array,
   * even if empty.
   */
  todos?: ITodo[];

  confirmationToken?: string | null;

  confirmationTokenExpires?: Date | null;

  isEmailConfirmed?: boolean;

  isBasicInfoUpdated?: boolean;

  fullName?: string;

  inviteCode?: string;

  gender?: Gender;

  birthday?: string;

  goal?: Goal;

  withPartner?: boolean;

  settings: IUserSettings;

  authSettings: IPublicAuthSettings;

  language?: string;

  role?: UserRoles;

  subscription?: IPublicSubscription;
}

export type ICreateUser = Pick<IUser, 'email' | 'password' | 'language'>;
export type IUpdateUserBasicUpdate = Pick<
  IUser,
  'withPartner' | 'goal' | 'gender' | 'birthday' | 'fullName' | 'settings'
>;
export type IUpdateUserBasicClient = Pick<
  IUser,
  'withPartner' | 'goal' | 'gender' | 'birthday' | 'fullName' | 'settings'
>;
export type IUpdateUser = Partial<Omit<IUser, 'id'>>;
export type IUpdateUserPassword = Pick<IUser, 'password'>;
export type IUpdateUserEmail = Pick<IUser, 'email'>;
export type IUserForPair = Pick<
  IUserShort,
  'email' | 'fullName' | 'settings' | 'id' | 'gender' | 'birthday'
>;
export type IUpsertUser = IUser;

/**
 * this was added so that we can consistently know which User properties
 * will be exposed in API payloads
 */
export type IPublicUserData = Omit<IUser, 'password'>;
