export enum WsEventsEnum {}

export enum WsEventsPair {
  PartnerJoined = 'PartnerJoined',
  PairCreated = 'PairCreated',
  PairJoined = 'PairJoined',
  PairLeft = 'PairLeft',
  PairMessages = 'PairMessages',
  JoinRoom = 'JoinRoom',
  JoinedRoom = 'JoinedRoom',
  GetPartnerStatus = 'GetPartnerStatus',
  PartnerSayILoveYou = 'PartnerSayILoveYou',
}
