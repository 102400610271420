import { BehaviorSubject, Observable } from 'rxjs';
import {
  AuthResendEmailBody,
  IAccessTokenPayload,
  IApiErrorResponseDev,
  ILoginPayload,
  ITokenResponse,
  SuccessResponse,
} from '@lc/shared/domain';

export interface IAuthService {
  setIsLoggedIn: boolean;
  getIsLoggedIn: boolean;
  getRefreshTokenSubject: BehaviorSubject<string | null>;
  getIsRefreshing: BehaviorSubject<boolean>;
  isLoggedInSub: Observable<boolean>;
  accessToken$: Observable<string | null>;
  userData$: Observable<IAccessTokenPayload | null>;
  setToken(access: string, refresh?: string): void;
  clearTokens(): void;
  setBasicInfoUpdatedToTrue(): void;
  getToken(): string | null;
  getUserData(): IAccessTokenPayload | null;
  getRefreshToken(): string | null;
  loadToken(): Observable<ITokenResponse | string | null>;
  loginUser(data: ILoginPayload): Observable<ITokenResponse | unknown>;
  handleTokens(
    access_token: string,
    refresh_token: string
  ): Observable<ITokenResponse>;
  logoutUser(): void;
  isTokenExpired(): boolean;
  isEmailConfirmed(): boolean;
  isUserRole(): boolean;
  isBasicInfoFilled(): boolean;
  isAllValidUser(): boolean;
  getPairId(): Observable<string>;
  // refreshTokenIfNeeded(): Observable<string | null>;
  refreshToken(): Observable<ITokenResponse>;
  googleLogin(): Observable<void>;
  appleLogin(): Observable<void>;
  confirmEmail(
    token: string
  ): Observable<SuccessResponse | IApiErrorResponseDev>;
  resendEmail(
    resendEmailBody: AuthResendEmailBody
  ): Observable<SuccessResponse | IApiErrorResponseDev>;
}
