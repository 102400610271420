import { NbGlobalPhysicalPosition } from '@nebular/theme';

export interface INotification {
  message: string | CRUDAction;
  title?: string;
  type: 'info' | 'success' | 'danger' | 'warning' | 'primary';
  duration?: string;
  position?: NbGlobalPhysicalPosition;
}

export enum CRUDAction {
  TagAdded = 'TagAdded',
  TagDeleted = 'TagDeleted',
  CharacteristicAdded = 'CharacteristicAdded',
  CharacteristicUpdated = 'CharacteristicUpdated',
  CharacteristicDeleted = 'Characteristic',
  CategoryAdded = 'CategoryAdded',
  CategoryUpdated = 'CategoryUpdated',
  SubscriptionAdded = 'SubscriptionAdded',
  SubscriptionUpdated = 'SubscriptionUpdated',
  TestCreated = 'TestCreated',
  TestUpdated = 'TestUpdated',
  QuestionAdded = 'QuestionAdded',
  QuestionUpdated = 'QuestionUpdated',
  TranslationAdded = 'TranslationAdded',
  TranslationUpdated = 'TranslationUpdated',
  TestTranslated = 'TestTranslated',
  AnswerAdded = 'AnswerAdded',
  AnswerUpdated = 'AnswerUpdated',
}
