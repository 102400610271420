import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AppStateService } from '../app-state.service';

@Injectable({
  providedIn: 'root',
})
export class AdminLoaderService {
  private _loadingMessage = 'Loading';

  private _isLoading = new BehaviorSubject<boolean>(true);
  public isLoading$ = this._isLoading
    .asObservable()
    .pipe
    // auditTime(500),
    // tap((t) => console.log('AdminLoaderService:isLoading$', t))
    ();
  // private _isLoading = new BehaviorSubject<boolean>(false);
  private _internalIsLoading = new BehaviorSubject<boolean>(false);

  // public isLoading$: Observable<boolean> = merge(
  //   this._internalIsLoading.pipe(
  //     filter(isLoading => isLoading),
  //     switchMap(() => timer(1).pipe(
  //       tap(() => console.log('Minimum display time elapsed')),
  //       switchMap(() => this._isLoading)
  //     ))
  //   ),
  //   this._internalIsLoading.pipe(
  //     filter(isLoading => !isLoading),
  //     switchMap(() => this._isLoading)
  //   )
  // );

  constructor(private appState: AppStateService) {}

  get isLoading() {
    return this.isLoading$;
  }

  set setLoading(isLoading: boolean) {
    if (!this.appState.isReady && !isLoading) {
      return;
    }
    // console.log('setLoading', isLoading);
    this._isLoading.next(isLoading);
    if (isLoading) {
      this._internalIsLoading.next(true);
    } else {
      this._internalIsLoading.next(false);
    }

    if (isLoading) {
      setTimeout(() => {
        this._internalIsLoading.next(false);
      }, 7000);
    }
  }

  get loadingMessage() {
    return this._loadingMessage;
  }

  setLoadingMessage(loadingMessage: string) {
    this._loadingMessage = loadingMessage;
  }
}
