export interface AuthResendEmailBody {
  email?: string;
  oldToken?: string;
}

export enum Gender {
  Man = 'man',
  Woman = 'woman',
  Other = 'other',
}

export enum Age {
  Less17 = 'Less 17',
  InRangeOf18_45 = '18-45',
  MoreThan46 = '46+',
}

export enum Goal {
  UnderstandThemselves = 'Understand themselves',
  ImproveRelationship = 'Improve my relationship with my partner',
  ForFun = 'For fun',
  AllAbove = 'All of the above',
  IDoNotKnow = 'I do not know or none of the options',
}

export enum Partnered {
  Alone = 'Only myself',
  WithPartner = 'With a partner ',
}

export enum UserRoles {
  User = 'User',
  Admin = 'Admin',
  SuperAdmin = 'SuperAdmin',
}
