import { IPublicUserData, IUserForPair } from './user.interface';

export interface IPairShort {
  id: string;

  createdAt: Date;

  user2JoinedAt?: Date;

  inviteCode?: string;

  active: boolean;

  user1: IUserForPair;
  user2: IUserForPair;
}
export interface IPair extends IPairShort {
  user1: IPublicUserData | IUserForPair;
  user2: IPublicUserData | IUserForPair;
}

export type ICreatePair = Pick<IPair, 'inviteCode'>;
export type IUpdatePair = Partial<Omit<IPair, 'id'>>;
export type IUpsertPair = IPair;
export type IPairForClient = Pick<
  IPairShort,
  'id' | 'createdAt' | 'user2JoinedAt' | 'active' | 'user1' | 'user2'
> & {
  user1: IUserForPair;
  user2: IUserForPair;
};

/**
 * this was added so that we can consistently know which Pair properties
 * will be exposed in API payloads
 */

export interface IJoinRoomPair {
  pairId: string;
  userId: string;
  partnerId: string;
}
