import {
  ActivatedRouteSnapshot,
  CanActivateFn,
  RouterStateSnapshot,
} from '@angular/router';
import { inject } from '@angular/core';
import { AUTH_SERVICE, IAuthService } from '../services';
import { SharedNavigationService } from '@lc/shared-app-helpers';

export const authGuard: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
) => {
  const authService: IAuthService = inject(AUTH_SERVICE);
  const navService: SharedNavigationService = inject(SharedNavigationService);

  const token: string | null = authService.getToken();
  const isEmailConfirmed: boolean = authService.isEmailConfirmed();
  // console.log(
  //   `[authGuard] canActivate: isEmailConfirmed=${isEmailConfirmed}, token=${token}`
  // );

  if (!isEmailConfirmed || !token) {
    navService.goToAuthLogin({
      queryParams: { returnUrl: state.url },
    });
    return false;
  }

  return true;
};
