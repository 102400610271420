export enum AdminEventBusEnum {
  UpdateView = 'UpdateView',
  FetchUsers = 'FetchUsers',
  Inited = 'Inited',
  UpdateAppData = 'UpdateAppData',
  FetchTests = 'FetchTests',
  FetchGoogleSpreadsheetData = 'FetchGoogleSpreadsheetData',
  FetchedGoogleSpreadsheetData = 'FetchedGoogleSpreadsheetData',
  TestsFetched = 'TestsFetched',
  FetchCategories = 'FetchCategories',
  FetchTags = 'FetchTags',
  FetchCharacteristics = 'FetchCharacteristics',
  FetchSubscriptions = 'FetchSubscriptions',
  ShowNotification = 'ShowNotification',
  StopLoading = 'StopLoading',
}
