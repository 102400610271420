import { IUser } from './user.interface';

export interface IAuthSettings {
  id: number;

  provider: 'google' | 'apple' | 'manual';

  refreshToken?: string | null;

  // a unique ID that provided by Google when sign in with Apple
  googleSub?: string | null;

  // a unique ID that provided by Apple when sign in with Apple
  appleSub?: string | null;

  user: IUser;
}

export type ICreateSocialAuthSettings = Pick<
  IAuthSettings,
  'provider' | 'refreshToken' | 'googleSub'
>;
export type ICreateManualAuthSettings = Pick<IAuthSettings, 'provider'>;
export type IUpdateAuthSettings = Pick<
  IAuthSettings,
  'provider' | 'refreshToken' | 'googleSub'
>;

/**
 * this was added so that we can consistently know which User properties
 * will be exposed in API payloads
 */
export type IPublicAuthSettings = Pick<IAuthSettings, 'provider'>;
