import { Component, Inject, OnDestroy } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  AUTH_SERVICE,
  IAuthService,
  LoginFormType,
} from '@lc/client/data-access';
import { ClientBaseComponent } from '../../client-base.component';

@Component({
  selector: 'lc-shared-login',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './shared-login.component.html',
  styleUrl: './shared-login.component.scss',
})
export abstract class SharedLoginComponent
  extends ClientBaseComponent
  implements OnDestroy
{
  public loginForm: FormGroup;

  protected constructor(
    @Inject(AUTH_SERVICE) protected authService: IAuthService
  ) {
    super();
    this.loginForm = new FormGroup<LoginFormType>({
      email: new FormControl<string>('', {
        nonNullable: true,
        validators: [Validators.required, Validators.email],
      }),
      password: new FormControl<string>('', {
        nonNullable: true,
        validators: [Validators.required],
      }),
    });
  }

  abstract openModal(error: string, description: string): void;

  abstract handleLoginError(err: any, email: string): void;

  protected abstract onSuccessLogin(): void;

  protected submitForm(): void {
    if (this.loginForm.valid && this.loginForm.dirty) {
      this.errorMessage$.next(null);
      const { email, password } = this.loginForm.getRawValue();
      this.authService
        .loginUser({ email, password })
        .pipe()
        .subscribe({
          next: () => {
            this.log(
              `User authenticated with [Manual], redirecting to dashboard...`
            );
            this.onSuccessLogin();
          },
          error: (err) => {
            this.handleLoginError(err, email);
          },
        });
    }
  }

  protected get emailInvalidAndTouched(): boolean {
    return (
      this.loginForm.controls['email'].invalid &&
      this.loginForm.controls['email'].touched
    );
  }

  protected get fEmail(): FormControl {
    return this.loginForm.controls['email'] as FormControl;
  }

  protected get fPassword(): FormControl {
    return this.loginForm.controls['password'] as FormControl;
  }

  protected getControl(name: string): FormControl {
    return this.loginForm.get(name) as FormControl;
  }
}
