import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { AdminEventBusEnum, AdminEventBusService } from './event-bus';

@Injectable({
  providedIn: 'root',
})
export class AppStateService {
  private _isAppReady = new BehaviorSubject<boolean>(true);
  public isAppReady$ = this._isAppReady.asObservable();

  constructor(
    private translate: TranslateService,
    events: AdminEventBusService
  ) {
    setTimeout(() => {
      events.emit(AdminEventBusEnum.UpdateView, AppStateService.name);
    }, 0);
    // events.emit(AdminEventBusEnum.UpdateView, AppStateService.name)
    this.translate.onLangChange.subscribe(() => {
      this._isAppReady.next(true);
    });

    this.translate.onTranslationChange.subscribe(() => {
      this._isAppReady.next(true);
    });

    this.translate.onDefaultLangChange.subscribe(() => {
      this._isAppReady.next(true);
    });

    // Check if translations are already loaded
    if (this.translate.currentLang) {
      this._isAppReady.next(true);
    }
  }

  public get isReady() {
    return this._isAppReady.getValue();
  }
}
