import { Injectable, OnDestroy } from '@angular/core';
import { Observable, tap } from 'rxjs';
import { ICategory } from '@lc/shared/domain';
import { ClientBaseService } from '../client-base.service';

@Injectable({
  providedIn: 'root',
})
export abstract class ClientCategoryService
  extends ClientBaseService<ICategory[]>
  implements OnDestroy
{
  constructor() {
    super('ClientCategoryService');
    this.modelObs = this.model$$.asObservable();
  }

  ngOnDestroy(): void {
    this.destroy();
  }

  protected getCategories(): Observable<ICategory[]> {
    return this.http.get<ICategory[]>(`${this.baseUrl}/categories`).pipe(
      tap((data) => {
        this.log(`getCategories - ${JSON.stringify(data)}`);
        this.model$$.next(data);
      })
    );
  }
}
