import { Observable } from 'rxjs';
import { IIdWrapper, ITranslation } from '@lc/shared/domain';

export interface ITranslationModification {
  createTranslation(
    testId: string,
    translation: ITranslation
  ): Observable<IIdWrapper>;

  updateTranslation(
    testId: string,
    translation: ITranslation
  ): Observable<ITranslation>;

  deleteTranslation(entityId: string, translationId: string): Observable<void>;
}
