import { Logger } from '@lc/client/util';
import { HttpClient } from '@angular/common/http';
import { inject } from '@angular/core';
import { environment } from '@lc/shared/util-env';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

export abstract class ClientBaseService<Model> extends Logger {
  protected model$$: BehaviorSubject<Model> = new BehaviorSubject<Model>(
    null as any
  );

  public modelObs: Observable<Model> = new Observable<Model>().pipe();

  protected readonly baseUrl: string = environment.apiUrl;
  protected readonly http: HttpClient = inject(HttpClient);
  protected destroy$: Subject<void> = new Subject<void>();

  destroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  override log(message: string) {
    super.log(message);
  }

  public getValue(): Model {
    return this.model$$.value;
  }
}
