export interface IPagination {
  skip?: number;
  take?: number;
  sortField?: TestSorting;
  sortOrder?: 'ASC' | 'DESC';
}

export enum TestSorting {
  UpdatedDate = 'updatedDate',
  CreatedDate = 'createdDate',
  Order = 'order',
}

export interface IDataReturn<S> {
  data: S[];
  count: number;
  totalPages: number;
}
