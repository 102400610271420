import { Injectable } from '@angular/core';
import { BehaviorSubject, filter, Observable } from 'rxjs';
import {
  ClientEventBus,
  EventModel,
  MobileEventBusEnum,
} from './client-event-bus.interface';
import { Logger } from '../client-logger';

@Injectable({
  providedIn: 'root',
})
export class ClientEventBusService
  extends Logger
  implements ClientEventBus<MobileEventBusEnum>
{
  private eventBus: BehaviorSubject<EventModel<MobileEventBusEnum>> =
    new BehaviorSubject<EventModel<MobileEventBusEnum>>({
      eventType: MobileEventBusEnum.Inited,
      data: undefined,
    });

  constructor() {
    super(ClientEventBusService.name);
  }
  emit(
    eventType: MobileEventBusEnum,
    eventOwner: string,
    data?: unknown
  ): void {
    // this.log(
    //   `event: "${eventType}" emitted by ${eventOwner}, data: ${JSON.stringify(
    //     data
    //   )}`
    // );
    this.eventBus.next({ eventType, data });
  }

  on(
    eventType: MobileEventBusEnum
  ): Observable<EventModel<MobileEventBusEnum>> {
    return this.eventBus.asObservable().pipe(
      filter((event) => {
        // this.log(`on, data: ${JSON.stringify(event)}`);
        return event.eventType === eventType;
      })
    );
  }
}
