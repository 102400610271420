import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { ClientEventBusService } from '@lc/client/util';
import { AuthService } from '@lc/client/data-access';

@Injectable({
  providedIn: 'root',
})
export class MobileAuthService extends AuthService {
  constructor(router: Router, events: ClientEventBusService) {
    super(router, events);
  }

  public getPairId(): Observable<string> {
    this.log('getPairId');
    return this.http.get<string>(`${this.baseUrl}/pairs/idOnly`);
  }
}
