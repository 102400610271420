import { Injectable } from '@angular/core';
import { ClientBaseService } from '../client-base.service';
import { ILanguage } from '@lc/shared/domain';

@Injectable({
  providedIn: 'root',
})
export class ClientLanguageService extends ClientBaseService<ILanguage[]> {
  constructor() {
    super(ClientLanguageService.name);
    this.model$$.next([
      {
        name: 'en',
        id: '123213213das',
      },
      {
        name: 'ua',
        id: '223213213das',
      },
      {
        name: 'ru',
        id: '323213213das',
      },
    ]);
  }

  getLangsAsString() {
    return this.getValue().map((l) => l.name);
  }
}
