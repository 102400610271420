import { Component, OnDestroy } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Logger } from '@lc/client/util';
import { BehaviorSubject, Subject } from 'rxjs';

@Component({
  selector: 'lc-client-base-component',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './client-base.component.html',
})
export class ClientBaseComponent extends Logger implements OnDestroy {
  protected destroy$: Subject<void> = new Subject<void>();
  public errorMessage$: BehaviorSubject<string | null> = new BehaviorSubject<
    string | null
  >(null);

  constructor() {
    super('ClientBaseComponentComponent');
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  protected setLoggerName(name: string) {
    super.setSource(name);
  }
}
