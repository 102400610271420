import { SubscriptionType } from '../enums';

export interface ITranslationWrapper extends IIdWrapper {
  translations?: ITranslation[];
}

interface ITest {
  id?: string;
  title: string;
  description: string;
  isForCouple: boolean;
  order: number;
  subscriptionType?: SubscriptionType;
  categoryId?: string;
  subscriptionId?: string;
  testType?: string;
  reccomendation?: string;
}

export interface ICreateTest extends ITest {
  tags: string[];
  translations: ITranslation[];
  questions: IQuestionOrUuid[];
}

export interface IShortPublicTest extends ITest {
  translations: string;
  questionCount: number;
  categoryName: string;
  tags: string;
}

export interface ICreateTestFromGoogleSheet extends ICreateTest {
  alreadyCreated: boolean;
  questions: IQuestion[];
}

export interface IPublicTest extends ITest {
  tags: ITag[];
  translations: ITranslation[];
  questions?: IQuestion[];
  category?: IPublicCategory;
  subscription?: IPublicSubscription;
}

export type IUpdateTest = Pick<ICreateTest, 'title' | 'description' | 'order'>;

export interface IQuestionOrUuid {
  id?: string;
  question?: IQuestion;
}

export interface IQuestion {
  id?: string;
  text: string;
  translations?: ITranslation[];
  test?: ITest;
  answers: IQuestionAnswer[];
  order: number;
  characteristics?: string[] | ICharacteristic[];
}

// export type ICreateQuestion = Partial<Omit<IQuestion, 'test'>>;
export type ICreateQuestion = Partial<
  Pick<
    IQuestion,
    'text' | 'answers' | 'characteristics' | 'translations' | 'order'
  >
>;

export type IUpdateQuestion = Pick<IQuestion, 'order'>;

export interface IQuestionAnswer {
  id?: string;
  text: string;
  translations?: ITranslation[];
  question?: IQuestion;
  order: number;
}

export type ICreateQuestionAnswer = IQuestionAnswer;
export type IUpdateQuestionAnswer = Pick<IQuestionAnswer, 'order'>;
export type IPublicQuestionAnswer = Pick<IQuestionAnswer, 'text' | 'id'>;

export interface ICategory extends ITranslationWrapper {
  id: string;
  name: string;
  tests?: ICreateTest[];
}

export type ICreateCategory = Partial<Omit<ICategory, 'tests' | 'id'>>;

export interface IPublicCategory {
  id: string;
  name: string;
  translations?: ITranslation[];
  tests?: IPublicTest[];
}

export interface ITag {
  id: string;
  name: string;
  translations: ITranslation[];
  tests?: ITest[];
  isPrivate: boolean;
}

export type ICreateTag = Partial<Omit<ITag, 'tests'>>;
export type IPublicTag = Pick<ITag, 'name' | 'id' | 'isPrivate'>;

export interface ICharacteristic extends ITranslationWrapper {
  name: string;
  test?: ITest;
}

export type ICreateCharacteristic = Partial<
  Omit<ICharacteristic, 'id' | 'test'>
>;

export type IPublicCharacteristic = Pick<ICharacteristic, 'name' | 'id'>;

export interface ITranslation {
  id?: string;
  language: string;
  text1: string;
  text2?: string | null;
}

export interface IIdWrapper {
  id?: string;
  updatedStatus?: string;
}

export interface ICategoryTranslation extends ITranslation {
  category: ICategory;
}

export interface ITestTranslation extends ITranslation {
  test: ITest;
}

export interface ITagTranslation extends ITranslation {
  tag: ITag;
}

export interface IQuestionTranslation extends ITranslation {
  question: IQuestion;
}

export interface IQuestionAnswerTranslation extends ITranslation {
  answer: IQuestionAnswer;
}

export interface IQuestionAnswerOrUuid {
  id?: string;
  answer?: ICreateQuestionAnswer;
}

export interface ICharacteristicTranslation extends ITranslation {
  characteristic: ICharacteristic;
}

export interface ISubscription extends ITranslationWrapper {
  enumName: SubscriptionType;
}

export type ICreateSubscription = Partial<Omit<ISubscription, 'id'>>;

export type IPublicSubscription = Pick<ISubscription, 'id' | 'enumName'>;

export interface ISubscriptionTranslation extends ITranslation {
  subscription: ISubscription;
}
