import { IUser } from './user.interface';
import { DefaultModel } from './default.interface';

export interface ITodo extends DefaultModel {
  id: string;
  title: string;
  description: string;
  completed: boolean;

  /**
   * These fields are marked as optional, as there
   * will be situations where the user is not returned
   * as part of the response payload.
   */
  user?: IUser;
  user_id?: string;
}

export type ICreateTodo = Pick<ITodo, 'title' | 'description'>;
export type IUpdateTodo = Partial<Omit<ITodo, 'id'>>;
export type IUpsertTodo = ITodo;
