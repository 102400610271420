import { Inject, Injectable } from '@angular/core';
import { io, Socket } from 'socket.io-client';
import {
  BehaviorSubject,
  combineLatest,
  EMPTY,
  fromEvent,
  map,
  Observable,
  of,
  retry,
  switchMap,
  tap,
} from 'rxjs';
import {
  AUTH_SERVICE,
  IAuthService,
  PairsService,
  UserService,
} from '../services';
import {
  ClientEventBusService,
  Logger,
  MobileEventBusEnum,
} from '@lc/client/util';
import { IJoinRoomPair, IPair, WsEventsPair } from '@lc/shared/domain';
import { catchError } from 'rxjs/operators';
import { environment } from '@lc/shared/util-env';

@Injectable({
  providedIn: 'root',
})
export class WebSocketService extends Logger {
  private socket: Socket | undefined;
  private readonly socketUrl: string = environment.wsUrl;
  private pair: IPair | undefined;
  private userId!: string;
  private isJoinedToRoom = false;
  private isSubscribedToMessages = false;

  constructor(
    @Inject(AUTH_SERVICE) private authService: IAuthService,
    userService: UserService,
    pairService: PairsService,
    private events: ClientEventBusService
  ) {
    super('WebSocketService');

    this.authService.isLoggedInSub.subscribe((isLoggedInSub) => {
      console.log(`isLoggedInSub - ${isLoggedInSub}`);
      if (!isLoggedInSub) {
        this.disconnect();
      }
    });

    this.authService.accessToken$
      .pipe(
        // filter((t) => {
        //   console.log('this.authService.isAllReady()', t, this.authService.isAllValidUser())
        //   return !!t && this.authService.isAllValidUser()
        // }),
        switchMap((token: string | null) => {
          if (!!token && this.authService.isAllValidUser()) {
            console.log('connect to socket');
            return combineLatest([
              userService.getUser().pipe(retry(1)),
              pairService.getPair().pipe(retry(1)),
            ]).pipe(
              map(([user, pair]) => ({ token, user, pair: pair })),
              tap((result) => {
                if (result) {
                  const { token, user, pair } = result;
                  this.userId = user.id;
                  this.pair = pair;
                  if (token && !this.authService.isTokenExpired()) {
                    if (!this.socket || this.socket.disconnected) {
                      this.connect(token, user.id);
                    }
                  } else {
                    this.disconnect();
                  }
                } else {
                  console.log(
                    'Result is null, likely due to error in fetching data'
                  );
                }
              }),
              catchError((error) => {
                this.error(`Error in combined streams, ${error}`);
                return of(null); // Возвращает null при ошибках
              })
            );
          } else {
            this.disconnect();
            return of('disconnected');
          }
        }),
        catchError((error) => {
          console.error('Error after token validation', error);
          return EMPTY;
        })
      )
      .subscribe();

    this.subEvents();
  }

  private subEvents(): void {
    this.events.on(MobileEventBusEnum.GetPartnerStatus).subscribe(() => {
      this.getPartnerStatus();
    });

    this.events.on(MobileEventBusEnum.PartnerSendSayILoveYou).subscribe(() => {
      this.sendSayILoveYou();
    });
  }

  private connect(token: string, userId: string): void {
    if (this.socket && !this.socket.disconnected) {
      this.log('WebSocket is already connected.');
      return;
    }

    this.socket = io(this.socketUrl, {
      query: { token, userId },
      autoConnect: true,
    });

    this.socket.on('connect', () => {
      this.log('Connected to WebSocket server');
      if (!this.isSubscribedToMessages) {
        this.subscribeToAll();
      }

      if (this.pair?.user1 && this.pair?.user2 && !this.isJoinedToRoom) {
        this.joinRoom(this.pair);
      } else {
        this.log('this.pair = null, so do not connect to the room');
      }
    });

    this.afterConnect();
  }

  public disconnect(): void {
    if (this.socket) {
      this.log('disconnect socket');
      this.socket.disconnect();
      this.socket = undefined;
      this.isJoinedToRoom = false;
      this.isSubscribedToMessages = false;
    }
  }

  public onMessage<T>(event: string): Observable<T> {
    if (!this.socket) {
      this.error('WebSocket is not connected.');
      return new BehaviorSubject<T>({} as T).asObservable();
    }
    return fromEvent(this.socket, event).pipe(tap(() => this.log(`${event}`)));
  }

  joinRoom(pair: IPair) {
    let userId = '';
    let partnerId = '';
    if (this.userId === pair.user1.id) {
      userId = pair.user1.id;
      partnerId = pair.user2.id;
    } else {
      userId = pair.user2.id;
      partnerId = pair.user1.id;
    }
    const joiningData = {
      pairId: pair.id,
      userId,
      partnerId: partnerId,
    };
    this.log(`joinRoom... ${JSON.stringify(joiningData)}`);
    this.socket?.emit(WsEventsPair.JoinRoom, joiningData as IJoinRoomPair);
    this.isJoinedToRoom = true;

    this.events.emit(
      MobileEventBusEnum.UpdatePartnerInfo,
      WebSocketService.name
    );
  }

  public sendMessageToPair(message: string): void {
    this.socket?.emit('message', { pairId: this.pair, message });
  }

  listenForMessages() {
    return new Observable((subscriber) => {
      this.socket?.on('message', (message) => {
        subscriber.next(message);
      });
    });
  }

  private subscribeToAll(): void {
    this.isSubscribedToMessages = true;
    this.onMessage('message').subscribe((data) => {
      this.log(`received message - ${JSON.stringify(data)}`);
    });

    this.onMessage(WsEventsPair.PartnerJoined).subscribe((data) => {
      this.log(
        `received ${WsEventsPair.PartnerJoined} - ${JSON.stringify(data)}`
      );
      this.joinRoomAfterPairCreated(data);
    });

    this.onMessage<IPair>(WsEventsPair.PairCreated).subscribe((data: any) => {
      this.log(
        `received ${WsEventsPair.PairCreated} - ${JSON.stringify(data)}`
      );
      this.joinRoomAfterPairCreated(data);
    });

    this.onMessage<IPair>(WsEventsPair.GetPartnerStatus).subscribe(
      (data: any) => {
        console.log(`received ${WsEventsPair.GetPartnerStatus}`, data);
        this.events.emit(
          MobileEventBusEnum.PartnerStatusChanged,
          WebSocketService.name,
          { status: data?.status }
        );
      }
    );

    this.onMessage<IPair>(WsEventsPair.PartnerSayILoveYou).subscribe(() => {
      this.log(`received ${WsEventsPair.PartnerSayILoveYou}`);
      this.events.emit(
        MobileEventBusEnum.PartnerSayILoveYou,
        WebSocketService.name
      );
    });

    this.onMessage(WsEventsPair.PairJoined).subscribe((data: unknown) => {
      this.log(`received ${WsEventsPair.PairJoined}, data - ${data}`);
    });

    this.onMessage(WsEventsPair.PairMessages).subscribe((data: unknown) => {
      this.log(`received ${WsEventsPair.PairMessages}, data - ${data}`);
      this.events.emit(
        MobileEventBusEnum.PartnerStatusChanged,
        WebSocketService.name,
        data
      );
    });

    this.onMessage(WsEventsPair.JoinedRoom).subscribe((data: unknown) => {
      this.log(`received ${WsEventsPair.JoinedRoom}, data - ${data}`);
    });
  }

  private joinRoomAfterPairCreated(data: any) {
    if (!data.pair) {
      throw new Error('Pair must be present');
    }
    this.pair = data.pair as IPair;
    if (this.pair.user2 && this.pair.user1) {
      if (this.pair && !this.isJoinedToRoom) {
        this.joinRoom(this.pair);
      }
    }
  }

  private afterConnect(): void {
    this.getPartnerStatus();
  }

  public getPartnerStatus(): void {
    this.socket?.emit(WsEventsPair.GetPartnerStatus, { userId: this.userId });
  }

  public sendSayILoveYou(): void {
    this.log(`sending ${WsEventsPair.PartnerSayILoveYou}`);
    this.socket?.emit(WsEventsPair.PartnerSayILoveYou, { userId: this.userId });
  }
}
