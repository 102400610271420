import {
  ActivatedRouteSnapshot,
  CanActivateFn,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { inject } from '@angular/core';
import { AUTH_SERVICE, IAuthService } from '../services';

export const redirectGuard: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
) => {
  const authService: IAuthService = inject(AUTH_SERVICE);
  const router: Router = inject(Router);
  const token: string | null = authService.getToken();
  const isEmailConfirmed: boolean = authService.isEmailConfirmed();
  if (token && isEmailConfirmed) {
    router.navigate([`/`]);
    return false;
  }
  return true;
};
