import { Injectable } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { Logger } from '@lc/client/util';

export interface INavigationService {
  goToAuthLogin(extras?: NavigationExtras): void;

  goToTabsDashboard(extras?: NavigationExtras): void;
}

@Injectable({
  providedIn: 'root',
})
export class SharedNavigationService
  extends Logger
  implements INavigationService
{
  constructor(private router: Router) {
    super(SharedNavigationService.name);
  }

  private navigateAuth(url: string, extras?: NavigationExtras): void {
    if (extras) {
      this.log(`navigate to: ${url}, extras: ${JSON.stringify(extras)}`);
      this.router.navigate([`auth/${url}`], extras);
    } else {
      this.log(`navigate to: ${url}`);
      this.router.navigate([`auth/${url}`]);
    }
  }

  private navigate(url: string, extras?: NavigationExtras): void {
    if (extras) {
      this.log(`navigate to: ${url}, extras: ${JSON.stringify(extras)}`);
      this.router.navigate([url], extras);
    } else {
      this.log(`navigate to: ${url}`);
      this.router.navigate([url]);
    }
  }

  goToAuthLogin(extras?: NavigationExtras): void {
    this.navigateAuth('login', extras);
  }

  goToTabsDashboard(extras?: NavigationExtras): void {
    this.navigate('dashboard', extras);
  }
}
