import { Observable } from 'rxjs';

export interface ClientEventBus<Types> {
  emit(eventType: Types, data: any): void;
  on(eventType: Types): Observable<any>;
}

export interface EventModel<S> {
  eventType: S;
  data: any;
}

export enum MobileEventBusEnum {
  PartnerStatusChanged = 'PartnerStatusChanged',
  FetchUser = 'FetchUser',
  FetchPair = 'FetchPair',
  Inited = 'Inited',
  GetPartnerStatus = 'GetPartnerStatus',
  UpdateAppData = 'UpdateAppData',
  UpdatePartnerInfo = 'UpdatePartnerInfo',
  PartnerSayILoveYou = 'PartnerSayILoveYou',
  PartnerSendSayILoveYou = 'PartnerSendSayILoveYou',
}

export interface PartnerStatusChanged {
  status: 'online' | 'offline';
}
