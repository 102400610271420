export enum NavigationUrls {
  Auth = 'auth',
  AuthLogin = 'login',
  AuthSignUp = 'sign-up',
  AuthPasswordRecover = 'password-recover',
  AuthEmailConfirmationSent = 'email-confirmation-sent',
  AuthEmailExpired = 'email-expired',
  AuthEmailSuccessfullyVerified = 'email-successfully-verified',
  AuthBasedQuestionsFlow = 'based-questions-flow',
  AuthInvitePartner = 'invite-partner',
  TestPreview = 'tabs/dashboard/test-preview',
  TestPassing = 'tabs/dashboard/passing-test',
  TabsHome = '/tabs/home',
  TabsProfile = '/tabs/profile',
  TabsDashboard = '/tabs/dashboard',
}
