/**
 * Used for standardizing the format with which API
 * errors are returned for bad requests
 */
export interface IApiErrorResponseProd {
  message: string;
  error: string;
}

export interface IApiErrorResponseDev {
  statusCode: number;
  message: string;
  timestamp?: string;
  path?: string;
  method?: string;
  errorName?: string;
}

export interface IHttpErrorResponse {
  error: IApiErrorResponseDev;
}
