import { Injectable } from '@angular/core';
import { NbGlobalPhysicalPosition, NbToastrService } from '@nebular/theme';
import {
  AdminEventBusEnum,
  AdminEventBusService,
  CRUDAction,
  INotification,
} from '../event-bus';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class AdminNotificationService {
  constructor(
    private toastr: NbToastrService,
    private translation: TranslateService,
    events: AdminEventBusService
  ) {
    events.on(AdminEventBusEnum.ShowNotification).subscribe((data) => {
      const notification = data.data as INotification;
      if (!(notification.message && notification.type)) {
        throw new Error(
          'notification data is incorrect, message and type are required'
        );
      }
      this.show(data.data as INotification);
    });
  }

  private show(notificationData: INotification) {
    switch (notificationData.type) {
      case 'danger':
        this.showDanger(notificationData);
        break;
      case 'success':
        this.showSuccess(notificationData);
        break;
    }
  }

  showDanger(notificationData: INotification) {
    this.toastr.danger(
      notificationData.message,
      notificationData.title || 'Error',
      {
        duration: 10000,
        position: NbGlobalPhysicalPosition.TOP_RIGHT,
        destroyByClick: true,
      }
    );
  }

  showSuccess(notificationData: INotification) {
    this.toastr.success(
      this.getMessage(notificationData.message as CRUDAction),
      notificationData.title || 'Success',
      {
        duration: 10000,
        position: NbGlobalPhysicalPosition.TOP_RIGHT,
        destroyByClick: true,
      }
    );
  }

  private getMessage(type: CRUDAction) {
    switch (type) {
      case CRUDAction.TestCreated:
        return this.translation.instant('Test is successfully created');
      case CRUDAction.TestUpdated:
        return this.translation.instant('Test is successfully updated');
      case CRUDAction.AnswerAdded:
        return this.translation.instant('Answer is successfully created');
      case CRUDAction.AnswerUpdated:
        return this.translation.instant('Answer is successfully updated');
      case CRUDAction.QuestionAdded:
        return this.translation.instant('Question is successfully created');
      case CRUDAction.QuestionUpdated:
        return this.translation.instant('Question is successfully updated');
      case CRUDAction.TranslationAdded:
        return this.translation.instant('Translation is successfully created');
      case CRUDAction.TranslationUpdated:
        return this.translation.instant('Translation is successfully updated');
      case CRUDAction.CategoryAdded:
        return this.translation.instant('Category is successfully created');
      case CRUDAction.CategoryUpdated:
        return this.translation.instant('Category is successfully updated');
      case CRUDAction.CharacteristicAdded:
        return this.translation.instant(
          'Characteristic is successfully created'
        );
      case CRUDAction.CharacteristicUpdated:
        return this.translation.instant(
          'Characteristic is successfully updated'
        );
      case CRUDAction.SubscriptionAdded:
        return this.translation.instant('Subscription is successfully created');
      case CRUDAction.SubscriptionUpdated:
        return this.translation.instant('Subscription is successfully updated');
    }

    return type;
  }
}
