import { Injectable } from '@angular/core';
import {
  IIdWrapper,
  ITranslation,
  ITranslationWrapper,
} from '@lc/shared/domain';
import { forkJoin, Observable, of, tap } from 'rxjs';
import { handleApiError } from '../../handle-api-error-response';
import { ClientBaseService } from '../client-base.service';
import { ITranslationModification } from './translation-service.interface';
import { FormArray } from '@angular/forms';
import { catchError } from 'rxjs/operators';
import { AdminLoaderService } from '@lc/admin-utils';

@Injectable({
  providedIn: 'root',
})
export class ClientTranslationsService<Model>
  extends ClientBaseService<Model>
  implements ITranslationModification
{
  protected url = '';
  protected domainName = '';

  completedRequestsCount = 0;
  totalRequestsCount = 0;

  constructor(protected loader: AdminLoaderService) {
    super(ClientTranslationsService.name);
  }

  getCompletedRequestsCount() {
    return this.completedRequestsCount;
  }

  getTotalRequestsCount() {
    return this.totalRequestsCount;
  }

  updateTranslation(
    entityId: string,
    translation: ITranslation
  ): Observable<ITranslation> {
    const body: ITranslation = {
      text1: translation.text1,
      text2: translation.text2,
      language: translation.language,
    };
    return this.http
      .patch<ITranslation>(
        `${this.url}/${entityId}/translations/${translation.id}`,
        body
      )
      .pipe(
        tap(() =>
          this.log(
            `update translation for ${this.domainName} ${entityId}, PATCH "${
              this.url
            }/", data: ${JSON.stringify(body)}`
          )
        ),
        handleApiError
      );
  }

  createTranslation(
    entityId: string,
    translation: ITranslation
  ): Observable<IIdWrapper> {
    const body: ITranslation = {
      text1: translation.text1,
      text2: translation.text2,
      language: translation.language,
    };
    const url = `${this.url}/${entityId}/translations`;
    return this.http.post<ITranslation>(url, body).pipe(
      tap(() =>
        this.log(
          `create translation for ${
            this.domainName
          } ${entityId}, POST "${url}", data: ${JSON.stringify(body)}`
        )
      ),
      handleApiError
    );
  }

  deleteTranslation(
    entityId: string,
    translationId: string
  ): Observable<never> {
    const url = `${this.url}/${entityId}/translations/${translationId}`;
    return this.http.delete<never>(url).pipe(
      tap(() =>
        this.log(
          `delete translation for ${this.domainName} ${entityId}, DELETE "${url}", data: translationId`
        )
      ),
      handleApiError
    );
  }

  public modifyTranslations(
    formArray: FormArray<any>,
    entity: ITranslationWrapper,
    errors: string[],
    сallback?: (p: 'success' | 'failed' | 'nothingToUpdate') => void
  ) {
    const originTranslations: ITranslation[] = entity.translations || [];
    if (!entity.id) {
      throw new Error('no ID when trying to update');
    }
    const testId: string = entity.id;
    const requests: Observable<any>[] = [];
    this.compareTranslations(
      formArray,
      originTranslations,
      testId,
      requests,
      errors
    );

    this.totalRequestsCount = requests.length;
    console.log(`Total requests to be sent: ${this.totalRequestsCount}`);
    this.loader.setLoadingMessage(
      `Loading ${this.completedRequestsCount}/${this.totalRequestsCount}`
    );
    if (requests.length === 0) {
      return сallback?.('nothingToUpdate');
    }
    this.loader.setLoading = true;
    forkJoin(requests).subscribe({
      next: (results) => {
        console.log('All requests completed successfully', results);
        сallback?.('success');
        this.loader.setLoading = false;
      },
      error: (err) => {
        console.error('Some requests failed', err);
        сallback?.('success');
        this.loader.setLoading = false;
      },
      complete: () => {
        console.log('All requests processed');
        this.loader.setLoading = false;
      },
    });
  }

  public modifyTranslationsLazy(
    formArray: FormArray<any>,
    entity: ITranslationWrapper,
    requests: Observable<any>[],
    errors: string[]
  ) {
    const originTranslations: ITranslation[] = entity.translations || [];
    if (!entity.id) {
      throw new Error('no ID when trying to update');
    }
    const testId: string = entity.id;
    this.compareTranslations(
      formArray,
      originTranslations,
      testId,
      requests,
      errors
    );

    this.totalRequestsCount = requests.length;
    console.log(`Total requests to be sent: ${this.totalRequestsCount}`);
    this.loader.setLoadingMessage(
      `Loading ${this.completedRequestsCount}/${this.totalRequestsCount}`
    );
    this.loader.setLoading = true;
  }

  private compareTranslations(
    formArray: FormArray<any>,
    originTranslations: ITranslation[],
    testId: string,
    requests: Observable<any>[],
    errors: string[]
  ) {
    formArray.controls.forEach((c) => {
      const formTranslation: ITranslation = c.getRawValue();
      // console.log('formTranslation id', formTranslation.id);
      if (formTranslation.id) {
        const needUpdate = originTranslations.find((originTranslation) => {
          const b =
            formTranslation.id === originTranslation.id &&
            (formTranslation.language !== originTranslation.language ||
              formTranslation.text1 !== originTranslation.text1 ||
              formTranslation.text2 !== originTranslation.text2);
          if (b) {
            // console.log('needUpdate', formTranslation, originTranslation);
          }
          return b;
        });
        if (needUpdate) {
          const update$ = this.updateTranslation(testId, formTranslation).pipe(
            tap((tUpdated) => {
              console.log('updated', tUpdated);
              const index = originTranslations.findIndex(
                (oT) => tUpdated.id === oT.id
              );
              if (index !== -1) {
                originTranslations[index] = tUpdated;
              }
              this.completedRequestsCount++;
              this.loader.setLoadingMessage(
                `Loading ${this.completedRequestsCount}/${this.totalRequestsCount}`
              );
            }),
            catchError((error) => {
              console.error('Update failed', error);
              errors.push(error);
              this.completedRequestsCount++;
              this.loader.setLoadingMessage(
                `Loading ${this.completedRequestsCount}/${this.totalRequestsCount}`
              );
              return of(null); // или можно выбросить ошибку return throwError(error)
            })
          );
          requests.push(update$);
        }
      } else {
        const create$ = this.createTranslation(testId, formTranslation).pipe(
          tap((t) => {
            console.log('translation created', t);
            originTranslations.push({ ...formTranslation, id: t.id });
            c.get('id')?.setValue(t.id);
            this.completedRequestsCount++;
            this.loader.setLoadingMessage(
              `Loading ${this.completedRequestsCount}/${this.totalRequestsCount}`
            );
          }),
          catchError((error) => {
            console.error('Creation failed', error);
            this.completedRequestsCount++;
            this.loader.setLoadingMessage(
              `Loading ${this.completedRequestsCount}/${this.totalRequestsCount}`
            );
            return of(null); // или можно выбросить ошибку return throwError(error)
          })
        );
        requests.push(create$);
      }
    });
  }

  getTranslationLanguages(translations: ITranslation[]) {
    return translations.map((t) => t.language);
  }
}
