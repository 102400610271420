import { HttpEvent, HttpHandlerFn, HttpRequest } from '@angular/common/http';
import { inject } from '@angular/core';
import {
  BehaviorSubject,
  filter,
  finalize,
  firstValueFrom,
  from,
  Observable,
  switchMap,
  take,
  throwError,
} from 'rxjs';
import { catchError } from 'rxjs/operators';
import { BaseLogger } from '@lc/client/util';
import { ApiEndpoints } from '@lc/shared/domain';
import { AUTH_SERVICE } from '../services';
import { IAuthService } from '../services/auth/auth-service.interface';

const excludeUrls: string[] = [
  '/api/v1/users',
  '/auth/',
  ApiEndpoints.AuthConfirmEmail,
  ApiEndpoints.AuthSendConfirmation,
];

const shouldAddHeader = (url: string) => {
  return excludeUrls.filter((u) => u.includes(url));
};

export const jwtInterceptor: (
  req: HttpRequest<unknown>,
  next: HttpHandlerFn
) => Observable<HttpEvent<unknown>> = (req, next: HttpHandlerFn) => {
  const authService: IAuthService = inject(AUTH_SERVICE);
  const isRefreshing: BehaviorSubject<boolean> = authService.getIsRefreshing;
  const refreshTokenSubject: BehaviorSubject<string | null> =
    authService.getRefreshTokenSubject;
  const logger: BaseLogger = new BaseLogger('jwtInterceptor');
  console.log('jwtInterceptor', req.url);
  if (
    req.url.includes('/auth/') ||
    (req.method === 'POST' && req.url.includes('/api/v1/users')) ||
    req.url.includes(ApiEndpoints.AuthConfirmEmail) ||
    req.url.includes(ApiEndpoints.AuthSendConfirmation) ||
    req.url.includes('/assets/')
  ) {
    // console.log('no need to modify header');
    return next(req);
  }
  const doNext = (
    reqParam: HttpRequest<unknown>
  ): Observable<HttpEvent<unknown>> => {
    return next(reqParam).pipe(
      catchError((error) => {
        if (error.status === 401 && !isRefreshing.value) {
          // Attempt to refresh token once
          if (!isRefreshing.value) {
            isRefreshing.next(true);
            refreshTokenSubject.next(null);

            return authService.refreshToken().pipe(
              switchMap((newToken) => {
                refreshTokenSubject.next(newToken.access_token);
                return next(appendToken(reqParam, newToken.access_token));
              }),
              catchError((refreshError) => {
                authService.logoutUser();
                logger.error(
                  `Could not refresh token, doing logout... ${JSON.stringify(
                    refreshError
                  )}`
                );
                return throwError(
                  () =>
                    new Error(
                      `Could not refresh token, doing logout... ${JSON.stringify(
                        refreshError
                      )}`
                    )
                );
              }),
              finalize(() => {
                isRefreshing.next(false);
              })
            );
          }
        } else {
          return throwError(() => error);
        }

        return refreshTokenSubject.pipe(
          filter((token) => token !== null),
          take(1),
          switchMap((token) => {
            return next(appendToken(reqParam, token || ''));
          })
        );
      })
    );
  };
  return from(firstValueFrom(authService.accessToken$)).pipe(
    filter((token) => {
      if (token === null) {
        logger.log('token is null, probably something is wrong');
        logger.warn('token is null, probably something is wrong');
      }
      return token !== null;
    }),
    switchMap((token) => {
      if (token && !authService.isTokenExpired()) {
        // Token is valid, append it to the request headers
        req = appendToken(req, token);
        return doNext(req);
      } else {
        if (isRefreshing.value) {
          // Если уже идет обновление, подождем нового токена
          return refreshTokenSubject.pipe(
            filter((token) => token !== null),
            take(1),
            switchMap((token) => next(appendToken(req, token || '')))
          );
        } else {
          isRefreshing.next(true);
          refreshTokenSubject.next(null);

          return authService.refreshToken().pipe(
            switchMap((newToken) => {
              refreshTokenSubject.next(newToken.access_token);
              return next(appendToken(req, newToken.access_token));
            }),
            catchError((refreshError) => {
              authService.logoutUser();
              logger.error(
                `Could not refresh token, doing logout... 1 ${JSON.stringify(
                  refreshError
                )}`
              );
              return throwError(
                () =>
                  new Error(
                    `Could not refresh token, doing logout... ${refreshError}`
                  )
              );
            }),
            finalize(() => {
              isRefreshing.next(false);
            })
          );
        }
      }
    })
  );
};

function appendToken(
  req: HttpRequest<unknown>,
  token: string
): HttpRequest<unknown> {
  return req.clone({
    setHeaders: {
      Authorization: `Bearer ${token}`,
    },
  });
}
