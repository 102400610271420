import { Component, OnDestroy } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoadingController } from '@ionic/angular';
import { ClientBaseComponent } from '@lc/shared-components';

@Component({
  selector: 'lc-mobile-base-feature',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './mobile-base-feature.component.html',
  styleUrl: './mobile-base-feature.component.scss',
})
export class MobileBaseFeatureComponent
  extends ClientBaseComponent
  implements OnDestroy
{
  constructor(private loadingCtrl: LoadingController) {
    super();
  }

  protected async showLoading(
    message = 'Loading...',
    duration = 7000
  ): Promise<void> {
    const loading = await this.loadingCtrl.create({
      message,
      duration,
    });

    return loading.present();
  }

  protected async hideLoading(): Promise<void> {
    await this.loadingCtrl.dismiss();
  }
}
