import { Route } from '@angular/router';
import { mobileAuthGuard, mobileBasicInfoGuard } from '@lc/mobile/data-access';

export const appRoutes: Route[] = [
  {
    path: '',
    loadChildren: () => import('@lc/mobile-tabs').then((m) => m.tabsRouting),
    canActivate: [mobileAuthGuard, mobileBasicInfoGuard],
  },
  {
    path: 'tabs/dashboard/test-preview',
    loadChildren: () =>
      import('@lc/client/mobile/features/tests/test-preview').then(
        (m) => m.testPreviewRoutes
      ),
    canActivate: [mobileAuthGuard],
  },
  {
    path: 'tabs/dashboard/passing-test',
    loadChildren: () =>
      import('@lc/client/mobile/features/tests/passing-test').then(
        (m) => m.passingTestRoutes
      ),
    canActivate: [mobileAuthGuard],
  },
  {
    path: 'auth',
    loadChildren: () =>
      import('@lc/client/mobile-auth').then((m) => m.authRoutes),
  },
];
