import { Injectable, OnDestroy } from '@angular/core';
import { ClientBaseService } from '../client-base.service';
import { Observable, takeUntil, tap } from 'rxjs';
import { IPair, IPairForClient, IUserForPair } from '@lc/shared/domain';
import { ClientEventBusService, MobileEventBusEnum } from '@lc/client/util';
import { handleApiError } from '../../handle-api-error-response';

@Injectable({ providedIn: 'root' })
export class PairsService
  extends ClientBaseService<IPairForClient>
  implements OnDestroy
{
  private pairUrl = `${this.baseUrl}/pairs`;

  constructor(private events: ClientEventBusService) {
    super('PairsService');
    this.modelObs = this.model$$.asObservable();
    this.subscribeToEvents();
  }

  ngOnDestroy(): void {
    this.destroy();
  }

  private subscribeToEvents(): void {
    this.events
      .on(MobileEventBusEnum.FetchPair)
      .pipe(takeUntil(this.destroy$))
      .subscribe((event) => {
        this.log(`subscribeToEvents call ${MobileEventBusEnum.FetchPair}`);
        this.getPair().subscribe();
      });
  }

  public getPairId(): Observable<any> {
    return this.http.get(`${this.pairUrl}/idOnly`);
  }

  public getPair(): Observable<IPair> {
    return this.http.get<IPair>(`${this.pairUrl}`).pipe(
      tap((u) => {
        this.log(`getPair, GET "${this.pairUrl}", data: ${JSON.stringify(u)}`);
        this.model$$.next(u);
        this.events.emit(
          MobileEventBusEnum.GetPartnerStatus,
          PairsService.name
        );
      }),
      handleApiError
    );
  }

  public leavePair(): Observable<IPair> {
    return this.http.delete<IPair>(`${this.pairUrl}`).pipe(
      tap((u) => {
        this.log(
          `leavePair, DELETE "${this.pairUrl}", data: ${JSON.stringify(u)}`
        );
        this.model$$.next(u);
      }),
      handleApiError
    );
  }

  public joinPair(inviteCode: string): Observable<IPairForClient> {
    return this.http
      .post<IPairForClient>(`${this.pairUrl}`, { inviteCode })
      .pipe(
        tap(() =>
          this.log(
            `joinPair, POST "${this.pairUrl}", inviteCode: ${JSON.stringify(
              inviteCode
            )}`
          )
        ),
        handleApiError
      );
  }

  public getPartner(userId: string): IUserForPair | null {
    const pair: IPairForClient | null = this.model$$.value;
    if (pair?.id) {
      if (pair.user1?.id === userId) {
        return pair.user2;
      } else if (pair.user2?.id === userId) {
        return pair.user1;
      } else {
        throw new Error('Current user is not in existing pair');
      }
    }
    return null;
  }
}
