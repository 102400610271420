import { bootstrapApplication } from '@angular/platform-browser';
import { AppComponent } from './app/app.component';
import {
  PreloadAllModules,
  provideRouter,
  RouteReuseStrategy,
  withPreloading,
} from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { APP_INITIALIZER, importProvidersFrom } from '@angular/core';
import { appRoutes } from './app/app.routes';
import {
  HttpClient,
  provideHttpClient,
  withInterceptors,
} from '@angular/common/http';
import { AUTH_SERVICE, jwtInterceptor } from '@lc/client/data-access';
import { ClientEventBusService, MobileEventBusEnum } from '@lc/client/util';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { MobileAuthService } from '@lc/mobile/data-access';

export const provideTranslation = () => ({
  defaultLanguage: 'en',
  loader: {
    provide: TranslateLoader,
    useFactory: HttpLoaderFactory,
    deps: [HttpClient],
  },
});

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

bootstrapApplication(AppComponent, {
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    importProvidersFrom(IonicModule.forRoot({})),
    importProvidersFrom([TranslateModule.forRoot(provideTranslation())]),
    provideRouter(appRoutes, withPreloading(PreloadAllModules)),
    {
      provide: APP_INITIALIZER,
      useFactory:
        (auth: MobileAuthService, events: ClientEventBusService) => () =>
          new Promise((resolve) => {
            console.log(`[APP_INIT] Loading token from storage..`);
            if (auth.getRefreshToken()) {
              auth.refreshToken().subscribe({
                next: (token) => {
                  console.log(`[APP_INIT] Token loaded successfully.`);
                  auth.setIsLoggedIn = true;
                  events.emit(MobileEventBusEnum.UpdateAppData, '[APP_INIT]');
                  resolve(token); // Resolve the promise when the token is successfully loaded
                },
                error: (error) => {
                  console.error(`[APP_INIT] Error loading token: `, error);
                  auth.clearTokens();
                  resolve(error); // Reject the promise if there is an error
                },
              });
            } else {
              resolve('no token');
            }
          }),
      deps: [AUTH_SERVICE, ClientEventBusService],
      multi: true,
    },
    { provide: AUTH_SERVICE, useClass: MobileAuthService },
    provideHttpClient(withInterceptors([jwtInterceptor])),
    // importProvidersFrom(MobileModalModule)
  ],
});
