import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'lc-shared-errors',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './shared-errors.component.html',
  styleUrl: './shared-errors.component.scss',
})
export class SharedErrorsComponent {
  @Input()
  public error!: string;

  @Input()
  public description!: string;
}
