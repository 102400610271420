import { Injectable } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { NavigationUrls } from '@lc/mobile-shared-domain';
import { Logger } from '@lc/client/util';

export interface INavigationService {
  goToAuthLogin(extras?: NavigationExtras): void;

  goToAuthSignUp(extras?: NavigationExtras): void;

  goToAuthPasswordRecover(extras?: NavigationExtras): void;

  goToAuthEmailConfirmationSent(extras?: NavigationExtras): void;

  goToAuthEmailExpired(extras?: NavigationExtras): void;

  goToAuthEmailSuccessfullyVerified(extras?: NavigationExtras): void;

  goToAuthBasedQuestionsFlow(extras?: NavigationExtras): void;

  goToInvitePartner(extras?: NavigationExtras): void;

  goToTestPreview(extras?: NavigationExtras): void;

  goToTestPassing(extras?: NavigationExtras): void;

  goToTabsHome(extras?: NavigationExtras): void;

  goToTabsProfile(extras?: NavigationExtras): void;

  goToTabsDashboard(extras?: NavigationExtras): void;
}

@Injectable({
  providedIn: 'root',
})
export class NavigationService extends Logger implements INavigationService {
  constructor(private router: Router) {
    super(NavigationService.name);
  }

  private navigateAuth(url: NavigationUrls, extras?: NavigationExtras): void {
    if (extras) {
      this.log(`navigate to: ${url}, extras: ${JSON.stringify(extras)}`);
      this.router.navigate([`${NavigationUrls.Auth}/${url}`], extras);
    } else {
      this.log(`navigate to: ${url}`);
      this.router.navigate([`${NavigationUrls.Auth}/${url}`]);
    }
  }

  private navigate(url: string, extras?: NavigationExtras): void {
    if (extras) {
      this.log(`navigate to: ${url}, extras: ${JSON.stringify(extras)}`);
      this.router.navigate([url], extras);
    } else {
      this.log(`navigate to: ${url}`);
      this.router.navigate([url]);
    }
  }

  private navigateTest(url: string) {
    this.router.navigate([``]);
  }

  goToAuthLogin(extras?: NavigationExtras): void {
    this.navigateAuth(NavigationUrls.AuthLogin, extras);
  }

  goToAuthSignUp(extras?: NavigationExtras): void {
    this.navigateAuth(NavigationUrls.AuthSignUp, extras);
  }

  goToAuthPasswordRecover(extras?: NavigationExtras): void {
    this.navigateAuth(NavigationUrls.AuthPasswordRecover, extras);
  }

  goToAuthEmailConfirmationSent(extras?: NavigationExtras): void {
    this.navigateAuth(NavigationUrls.AuthEmailConfirmationSent, extras);
  }

  goToAuthEmailExpired(extras?: NavigationExtras): void {
    this.navigateAuth(NavigationUrls.AuthEmailExpired, extras);
  }

  goToAuthEmailSuccessfullyVerified(extras?: NavigationExtras): void {
    this.navigateAuth(NavigationUrls.AuthEmailSuccessfullyVerified, extras);
  }

  goToAuthBasedQuestionsFlow(extras?: NavigationExtras): void {
    this.navigateAuth(NavigationUrls.AuthBasedQuestionsFlow, extras);
  }

  goToInvitePartner(extras?: NavigationExtras): void {
    this.navigateAuth(NavigationUrls.AuthInvitePartner, extras);
  }

  goToTestPreview(extras?: NavigationExtras): void {
    this.navigateAuth(NavigationUrls.TestPreview, extras);
  }

  goToTestPassing(extras?: NavigationExtras): void {
    this.navigateAuth(NavigationUrls.TestPassing, extras);
  }

  goToTabsHome(extras?: NavigationExtras): void {
    this.navigate(NavigationUrls.TabsHome, extras);
  }

  goToTabsProfile(extras?: NavigationExtras): void {
    this.navigate(NavigationUrls.TabsProfile, extras);
  }

  goToTabsDashboard(extras?: NavigationExtras): void {
    this.navigate(NavigationUrls.TabsDashboard, extras);
  }
}
