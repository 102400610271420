import { IDataReturn } from './pagination.interface';
import { ICreateTestFromGoogleSheet } from './test.interface';

export interface IGoogleSheetData {
  tests: IDataReturn<ICreateTestFromGoogleSheet>;
  categories: string[];
  characteristics: string[];
  titles?: string[];
}

export interface IAlreadyCreatedEntity {
  alreadyCreated: boolean;
  entity: string;
}

export interface IGoogleSheetClientData {
  tests: IDataReturn<ICreateTestFromGoogleSheet>;
  categories: IAlreadyCreatedEntity[];
  characteristics: IAlreadyCreatedEntity[];
  titles?: string[];
}
