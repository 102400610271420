import { Injectable, NgZone } from '@angular/core';
import { App } from '@capacitor/app';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class DeepLinkService {
  constructor(private router: Router, private zone: NgZone) {
    this.initializeDeepLink();
  }

  public initializeDeepLink(): void {
    App.addListener('appUrlOpen', (data) => {
      this.zone.run(() => {
        // console.log('App opened with URL:', data.url);
        // Предполагаем, что URL имеет вид: yourapp://path?params
        const slug = data.url.split('://')[1];
        if (slug) {
          console.log(slug);
          this.router.navigateByUrl('/' + slug);
        }
      });
    });
  }
}
