import {
  ActivatedRouteSnapshot,
  CanActivateFn,
  RouterStateSnapshot,
} from '@angular/router';
import { inject } from '@angular/core';
import { NavigationService } from '@lc/mobile/shared/app-helpers';
import { AUTH_SERVICE, IAuthService } from '@lc/client/data-access';

export const mobileBasicInfoGuard: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
) => {
  const authService: IAuthService = inject(AUTH_SERVICE);
  const navService: NavigationService = inject(NavigationService);
  const isEmailConfirmed: boolean = authService.isEmailConfirmed();
  const isBasicInfoFilled: boolean = authService.isBasicInfoFilled();
  console.log(`[authGuard] canActivate: isEmailConfirmed=${isEmailConfirmed}`);

  if (!isBasicInfoFilled) {
    console.log('goToAuthBasedQuestionsFlow');
    navService.goToAuthBasedQuestionsFlow({
      queryParams: { returnUrl: state.url },
    });
    return false;
  }
  return true;
};
