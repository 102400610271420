import { Injectable, OnDestroy } from '@angular/core';
import { ClientBaseService } from '../client-base.service';
import { ITag } from '@lc/shared/domain';
import { Observable, tap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ClientTagsService
  extends ClientBaseService<ITag[]>
  implements OnDestroy
{
  constructor() {
    super('ClientTagsService');
    this.modelObs = this.model$$.asObservable();
  }

  ngOnDestroy(): void {
    this.destroy();
  }

  public getTags(): Observable<ITag[]> {
    this.log('getTags');
    return this.http.get<ITag[]>(`${this.baseUrl}/tags`).pipe(
      tap((data) => {
        this.model$$.next(data);
      })
    );
  }
}
