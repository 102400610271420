import { HttpHeaders } from '@angular/common/http';
import { jwtDecode } from 'jwt-decode';
import {
  BehaviorSubject,
  from,
  map,
  Observable,
  of,
  shareReplay,
  switchMap,
  tap,
} from 'rxjs';
import {
  ApiEndpoints,
  AuthResendEmailBody,
  IAccessTokenPayload,
  IApiErrorResponseDev,
  ILoginPayload,
  ITokenResponse,
  IUser,
  SuccessResponse,
  UserRoles,
} from '@lc/shared/domain';
import { handleApiError } from '../../handle-api-error-response';
import {
  ACCESS_TOKEN_STORAGE_KEY,
  ClientEventBusService,
  REFRESH_TOKEN_STORAGE_KEY,
} from '@lc/client/util';
import { ClientBaseService } from '../client-base.service';
import { Browser } from '@capacitor/browser';
import { Router } from '@angular/router';
import { IAuthService } from './auth-service.interface';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};

export abstract class AuthService
  extends ClientBaseService<IUser>
  implements IAuthService
{
  private accessToken$$: BehaviorSubject<string | null> = new BehaviorSubject<
    string | null
  >(null);
  private userData$$: BehaviorSubject<IAccessTokenPayload | null> =
    new BehaviorSubject<IAccessTokenPayload | null>(null);

  private refreshTokenSubject = new BehaviorSubject<string | null>(null);
  private isRefreshing = new BehaviorSubject<boolean>(false);

  // private isLoggedInSubject = new BehaviorSubject<boolean>(false);
  private isLoggedIn$$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  public isLoggedInSub: Observable<boolean> = new Observable<boolean>();

  protected constructor(
    protected router: Router,
    protected events: ClientEventBusService
  ) {
    super('AuthService');
    console.log('AuthService constructor');
    this.isLoggedInSub = this.isLoggedIn$$.asObservable();
  }

  set setIsLoggedIn(value: boolean) {
    this.isLoggedIn$$.next(value);
  }

  get getIsLoggedIn() {
    return this.isLoggedIn$$.value;
  }

  get getRefreshTokenSubject(): BehaviorSubject<string | null> {
    return this.refreshTokenSubject;
  }

  get getIsRefreshing(): BehaviorSubject<boolean> {
    return this.isRefreshing;
  }

  /**
   * The encoded token is stored so that it can be used by an interceptor
   * and injected as a header
   */
  accessToken$: Observable<string | null> = this.accessToken$$.pipe(
    shareReplay(1)
  );

  /**
   * Data from the decoded JWT including a user's ID and email address
   */
  userData$: Observable<IAccessTokenPayload | null> = this.userData$$.pipe();

  setToken(access: string, refresh?: string): void {
    this.accessToken$$.next(access);
    console.log('setToken', access);
    localStorage.setItem(ACCESS_TOKEN_STORAGE_KEY, access);
    if (refresh) {
      localStorage.setItem(REFRESH_TOKEN_STORAGE_KEY, refresh);
    }
  }

  clearTokens() {
    this.userData$$.next(null);
    this.accessToken$$.next(null);
    localStorage.removeItem(ACCESS_TOKEN_STORAGE_KEY);
    localStorage.removeItem(REFRESH_TOKEN_STORAGE_KEY);
  }

  /**
   * Must be updated once only after basedQuestion is competed
   */
  public setBasicInfoUpdatedToTrue(): void {
    if (this.userData$$.value) {
      this.userData$$.next({
        ...this.userData$$.value,
        ...{ isBasicInfoUpdated: true },
      });
    } else {
      throw new Error('this.userData$$ must be present');
    }
  }

  public getToken(): string | null {
    return this.accessToken$$.value;
  }

  public getUserData(): IAccessTokenPayload | null {
    return this.userData$$.value;
  }

  public getRefreshToken(): string | null {
    return localStorage.getItem(REFRESH_TOKEN_STORAGE_KEY);
  }

  loadToken(): Observable<ITokenResponse | string | null> {
    const token = localStorage.getItem(ACCESS_TOKEN_STORAGE_KEY);
    this.log(`Loaded token: ${token?.slice(0, 12)}`);
    if (token && !this.isTokenExpired()) {
      this.accessToken$$.next(token);
      this.userData$$.next(this.decodeToken(token));
      return of(token);
    } else if (token && this.isTokenExpired() && this.getRefreshToken()) {
      return this.refreshToken();
    } else {
      return of(token);
    }
  }

  loginUser(data: ILoginPayload): Observable<ITokenResponse | unknown> {
    this.log(`[AuthService] Logging in...`);
    return this.http
      .post<ITokenResponse>(`${this.baseUrl}/auth/login`, data, httpOptions)
      .pipe(
        switchMap(
          ({ access_token, refresh_token }): Observable<ITokenResponse> => {
            return this.handleTokens(access_token, refresh_token);
          }
        ),
        handleApiError
      );
  }

  /**
   * decode and save access and refresh tokens after login
   * @param access_token
   * @param refresh_token
   */
  public handleTokens(
    access_token: string,
    refresh_token: string
  ): Observable<ITokenResponse> {
    const decodeToken: IAccessTokenPayload | null =
      this.decodeToken(access_token);
    this.log(`set token: ${JSON.stringify(decodeToken)}`);
    if (!decodeToken?.pairId) {
      this.log('does not have a pair, fetching...');
      this.userData$$.next(decodeToken);
      this.setToken(access_token, refresh_token);
      return this.getPairId().pipe(
        tap((id) => {
          if (decodeToken) {
            decodeToken.pairId = id;
            this.userData$$.next(decodeToken);
            this.setIsLoggedIn = true;
          }
        }),
        switchMap(() => of({ access_token, refresh_token }))
      );
    } else {
      this.log('has a pair, continue');
      this.userData$$.next(decodeToken);
      this.setToken(access_token, refresh_token);
      this.setIsLoggedIn = true;
      return of({ access_token, refresh_token });
    }
  }

  logoutUser() {
    this.log(`[AuthService] Logout...`);
    this.clearTokens();
    this.isLoggedIn$$.next(false);
    this.navigateToLogin();
  }

  //  @TODO replace to take value from NavigationUrls
  private navigateToLogin(): void {
    this.router.navigate([`auth/login`]);
  }

  public navigateToBasedQeuestions(): void {
    this.router.navigate([`auth/based-questions-flow`]);
  }

  /**
   * Compares the `exp` field of a token to the current time. Returns
   * a boolean with a 5 sec grace period.
   */
  isTokenExpired(): boolean {
    const expiryTime = this.userData$$.value?.['exp'];
    this.log(`Checking for token expiration...`);
    if (expiryTime) {
      const expireTs = 1000 * +expiryTime;
      const now = new Date().getTime();
      this.log(
        `Time left to expiration: ${Math.round(
          (expireTs - now) / 1000
        )} seconds`
      );
      return expireTs - now <= 0;
    }
    this.log(`No expiration time found! Setting expired to true`);
    return true;
  }

  public isEmailConfirmed(): boolean {
    const isEmailConfirmed: boolean | undefined =
      this.userData$$.value?.['isEmailConfirmed'];
    return !!isEmailConfirmed;
  }

  public isUserRole(): boolean {
    return this.getUserData()?.role === UserRoles.Admin;
  }

  public isBasicInfoFilled(): boolean {
    const isBasicInfoUpdated: boolean | undefined =
      this.userData$$.value?.['isBasicInfoUpdated'];
    return !!isBasicInfoUpdated;
  }

  public isAllValidUser(): boolean {
    return this.isBasicInfoFilled() && this.isEmailConfirmed();
  }

  public decodeToken(token: string | null): IAccessTokenPayload | null {
    if (token) {
      this.log(`decodeToken: ${JSON.stringify(jwtDecode(token))}`);
      return jwtDecode(token) as IAccessTokenPayload;
    }
    return null;
  }

  abstract getPairId(): Observable<string>;

  refreshTokenIfNeeded(): Observable<string | null> {
    const currentToken: string | null = this.accessToken$$.getValue();
    if (this.isTokenExpired()) {
      return this.refreshToken().pipe(
        tap((tokenResponse) =>
          this.accessToken$$.next(tokenResponse.access_token)
        ),
        map((tokenResponse) => tokenResponse.access_token)
      );
    }
    return of(currentToken);
  }

  public refreshToken(): Observable<ITokenResponse> {
    const refreshToken: string | null = this.getRefreshToken();
    return this.http
      .post<ITokenResponse>(`${this.baseUrl}/auth/refresh`, {
        refresh_token: refreshToken,
      })
      .pipe(
        map((response) => {
          this.userData$$.next(this.decodeToken(response.access_token));
          this.setToken(response.access_token);
          return response;
        })
      );
  }

  /**
   * Initiate Google Login flow
   */
  public googleLogin(): Observable<void> {
    const openCapacitorSite = async () => {
      await Browser.open({ url: `${this.baseUrl}/auth/google` });
    };
    return from(openCapacitorSite()).pipe(
      tap(() => {
        Browser.addListener('browserPageLoaded', () => {
          console.log('Browser page loaded');
          // if (info.url.startsWith('https://com.lovecharger.app/auth/login')) {
          //   await Browser.close();
          // }
        }).finally(() => {
          console.log('finally');
        });
      })
    );
  }

  /**
   * Initiate Apple Login flow
   */
  public appleLogin(): Observable<void> {
    const openCapacitorSite = async () => {
      await Browser.open({ url: `${this.baseUrl}/auth/apple` });
    };
    return from(openCapacitorSite());
  }

  /**
   * Confirm email address by token
   * @param token
   */
  public confirmEmail(
    token: string
  ): Observable<SuccessResponse | IApiErrorResponseDev> {
    return this.http.post<SuccessResponse | IApiErrorResponseDev>(
      `${this.baseUrl}/auth/${ApiEndpoints.AuthConfirmEmail}`,
      { token }
    );
  }

  /**
   * Send confirmation email to the User.
   *   - pass {email} if user don't have oldToken
   *   - pass {oldToken} in case if the token is expired.
   * @param resendEmailBody
   *
   */
  public resendEmail(
    resendEmailBody: AuthResendEmailBody
  ): Observable<SuccessResponse | IApiErrorResponseDev> {
    return this.http.post<SuccessResponse | IApiErrorResponseDev>(
      `${this.baseUrl}/auth/${ApiEndpoints.AuthSendConfirmation}`,
      resendEmailBody
    );
  }
}
