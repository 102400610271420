export enum AuthErrors {
  EMAIL_NOT_CONFIRMED = 'EMAIL_NOT_CONFIRMED',
  EMAIL_IS_UNDEFINED = 'EMAIL_IS_UNDEFINED',
  USERNAME_OR_PASSWORD_IS_INCORRECT = 'USERNAME_OR_PASSWORD_IS_INCORRECT',
  USER_NOT_FOUND = 'USER_NOT_FOUND',
  NOT_FOUND = 'NOT_FOUND',
  ALREADY_IN_USE = 'ALREADY_IN_USE',
  INVALID_TOKEN = 'INVALID_TOKEN',
  ALREADY_EXISTS = 'ALREADY_EXISTS',
  ACCESS_DENIED = 'ACCESS_DENIED',
  EMAIL_CONFIRMATION_ERROR = 'EMAIL_CONFIRMATION_ERROR',
  EMAIL_CONFIRMATION_EXPIRED = 'EMAIL_CONFIRMATION_EXPIRED',
  EMAIL_OR_PASSWORD_IS_INVALID = 'EMAIL_OR_PASSWORD_IS_INVALID',
  ID_NOT_THE_SAME_AS_LOGGED_USER = 'ID_NOT_THE_SAME_AS_LOGGED_USER',
  INCORRECT_PROVIDER_LOGIN = 'INCORRECT_PROVIDER_LOGIN',
  SERVER_ERROR = 'SERVER_ERROR',
}

export enum TestErrorsEnum {
  TEST_IS_NOT_COMPLETED = 'TEST_IS_NOT_COMPLETED',
}

export enum PairErrors {
  INVITE_CODE_IS_REQUIRED = 'INVITE_CODE_IS_REQUIRED',
  CODE_ALREADY_USED = 'CODE_ALREADY_USED',
  INVITE_CODE_HOLDER_NOT_FOUND = 'INVITE_CODE_HOLDER_NOT_FOUND',
  JOINING_USER_NOT_FOUND = 'JOINING_USER_NOT_FOUND',
  PAIR_NOT_FOUND = 'PAIR_NOT_FOUND',
}
