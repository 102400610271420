import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class ClientErrorHandlerService {
  constructor(private translate: TranslateService) {}

  public getErrorMessage(error: string): string {
    return this.translate.instant(`ERRORS.${error}`);
  }
}
