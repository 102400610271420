import { Injectable, OnDestroy } from '@angular/core';
import { ClientBaseService } from '../client-base.service';
import { IPublicSubscription, ISubscription } from '@lc/shared/domain';
import { Observable, tap } from 'rxjs';
import { handleApiError } from '../../handle-api-error-response';

@Injectable({
  providedIn: 'root',
})
export abstract class ClientSubscriptionsService
  extends ClientBaseService<ISubscription[]>
  implements OnDestroy
{
  private subscriptionsUrl = `${this.baseUrl}/subscriptions`;

  protected constructor() {
    super(ClientSubscriptionsService.name);
    this.modelObs = this.model$$.asObservable();
  }

  ngOnDestroy(): void {
    this.destroy();
  }

  protected getData(): Observable<IPublicSubscription[]> {
    return this.http
      .get<IPublicSubscription[]>(`${this.subscriptionsUrl}`)
      .pipe(
        tap((u) => {
          this.log(
            `getSubscriptions, GET "${
              this.subscriptionsUrl
            }", data: ${JSON.stringify(u)}`
          );
          this.model$$.next(u);
        }),
        handleApiError
      );
  }
}
