import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';

// export function handleApiError(obs: Observable<unknown>): Observable<unknown> {
//   return obs.pipe(
//     catchError((err) => {
//       if (isApiError(err)) {
//         const returnMsg = extractErrorMessage(err);
//         return throwError(() => returnMsg);
//       } else if (err instanceof HttpErrorResponse) {
//         return throwError(() => err.statusText || 'Unknown Http Error');
//       }
//       return throwError(() => err || 'An unknown error occurred');
//     })
//   );
// }

export function handleApiError<T>(obs: Observable<T>): Observable<T> {
  return obs.pipe(
    catchError((err) => {
      if (isApiError(err)) {
        const returnMsg = extractErrorMessage(err);
        return throwError(() => new Error(returnMsg)) as Observable<never>;
      } else if (err instanceof HttpErrorResponse) {
        return throwError(
          () => new Error(err.statusText || 'Unknown Http Error')
        ) as Observable<never>;
      }
      return throwError(
        () => new Error(err?.message || 'An unknown error occurred')
      ) as Observable<never>;
    })
  );
}

export function extractErrorMessage(err: any) {
  const errContent: HttpErrorResponse = err.error;
  const { message } = errContent;
  let returnMsg = message;
  if (Array.isArray(message)) {
    returnMsg = message.join(' ');
  }
  return returnMsg;
}

export function isApiError(err: unknown): boolean {
  if (err instanceof HttpErrorResponse) {
    if (
      Object.keys(err.error).includes('message') &&
      Object.keys(err.error).includes('errorName')
    ) {
      return true;
    }
  }
  return false;
}
