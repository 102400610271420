import { Injectable, OnDestroy } from '@angular/core';
import { Observable, takeUntil, tap } from 'rxjs';
import {
  ICreateUser,
  IPublicUserData,
  IUpdateUserBasicUpdate,
} from '@lc/shared/domain';
import { handleApiError } from '../handle-api-error-response';
import { ClientBaseService } from './client-base.service';
import { ClientEventBusService, MobileEventBusEnum } from '@lc/client/util';

@Injectable({
  providedIn: 'root',
})
export class UserService
  extends ClientBaseService<IPublicUserData>
  implements OnDestroy
{
  private usersUrl = `${this.baseUrl}/users`;

  constructor(private events: ClientEventBusService) {
    super('UserService');
    this.modelObs = this.model$$.asObservable();
    this.subscribeToEvents();
  }

  ngOnDestroy(): void {
    this.destroy();
  }

  subscribeToEvents(): void {
    this.events
      .on(MobileEventBusEnum.FetchUser)
      .pipe(takeUntil(this.destroy$))
      .subscribe((event) => {
        this.log(`subscribeToEvents call ${MobileEventBusEnum.FetchUser}`);
        this.getUser().subscribe();
      });
  }

  public getSavedUser(): IPublicUserData | null {
    return this.model$$.value;
  }

  getUser(): Observable<IPublicUserData> {
    return this.http.get<IPublicUserData>(`${this.usersUrl}`).pipe(
      tap((u) => {
        this.log(`getUser, GET "${this.usersUrl}", data: ${JSON.stringify(u)}`);
        this.model$$.next(u);
      }),
      handleApiError
    );
  }

  updateUser(data: IUpdateUserBasicUpdate): Observable<IPublicUserData> {
    const url = `${this.usersUrl}/update-basic`;
    return this.http.patch<IPublicUserData>(url, data).pipe(
      tap(() =>
        this.log(`updateUser, PATCH "${url}", data: ${JSON.stringify(data)}`)
      ),
      handleApiError
    );
  }

  createUser(data: ICreateUser): Observable<IPublicUserData> {
    return this.http.post<IPublicUserData>(this.usersUrl, data).pipe(
      tap(() =>
        this.log(
          `updateUser, POST "${this.usersUrl}", data: ${JSON.stringify(data)}`
        )
      ),
      handleApiError
    );
  }
}
